import {environment} from "../global/app";
import {IEnv} from "../interfaces/env";
import {RestService} from "./rest.service";
import {Observable} from "rxjs";
import {IShop} from "../interfaces/shop";

class EnvironmentServiceController extends RestService {
  private environment: IEnv = environment;
  constructor() {
    super();
    this.setApi(environment.REST_API);
  }


  // get environment with url overwrites
  public getEnvironment(): IEnv {
    return this.environment;
  }

  public get(urlObject?:{url:string}): Observable<any> {
    return super.read(`/shop/config`,urlObject);
  }
  public updateShopConfig(shopConfig:IShop): void {
    const updatedConfig: Partial<IEnv> = {
      ...this.environment,
      shopId: shopConfig.shopId,
      projectName:shopConfig.projectName,
      deliveryCost:shopConfig.deliveryCost,
      delivery:shopConfig.delivery,
      ...shopConfig.config  // assuming config contains valid IEnv properties
    };

    this.environment = updatedConfig as IEnv;
  }
}

export const EnvironmentService = new EnvironmentServiceController();
