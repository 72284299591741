import {IArticle} from "../interfaces/article";
import {IArticleOption, IOriginalArticleOptions} from "../interfaces/articleOption";
import {ICategory} from "../interfaces/category";
import {i18n} from "../services/i18n.service";
import {ErrorToast} from "./default-toast";


export const GetLowestPrice = (article: IArticle, industrialPrice=false) => {
  let lowestPrice = 0;
  Object.values(article.options).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      const price = ChangePrice(1000000, industrialPrice ? option.industry_price : option.customer_price);
      if (lowestPrice === 0 || price < lowestPrice) {
        lowestPrice = price;
      }
    });
  });
  return lowestPrice;
}

export const HasMoreThanOnePrice = (article: IArticle, industrialPrice=false) => {
  let scalePrice = false;
  Object.values(article.options).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      const priceArray = industrialPrice ? option.industry_price : option.customer_price
      scalePrice = (priceArray.length > 1);
    });
  });
  return scalePrice;
}
export const CalculateOptionPrice = (data: any[] | any): number => {
  if (!data) return 0; // Return 0 if data is undefined or null.

  // Handle an array of objects
  if (Array.isArray(data)) {
    return data.reduce((total, obj) => total + CalculateOptionPrice(obj), 0);
  }

  // Handle a single object
  let totalAmount = data.price || 0;

  // Recursively sum selectedRequiredArticles
  if (data.selectedRequiredArticles && Array.isArray(data.selectedRequiredArticles)) {
    data.selectedRequiredArticles.forEach((article:IArticle) => {
      totalAmount += CalculateOptionPrice(article);
    });
  }

  // Recursively sum selectedOptionArticles
  if (data.selectedOptionArticles && Array.isArray(data.selectedOptionArticles)) {
    data.selectedOptionArticles.forEach((article:IArticle) => {
      totalAmount += CalculateOptionPrice(article);
    });
  }

  return totalAmount;
};


export const CalculatePrice = (article: IArticleOption|IArticle, amount: number,industrialPrice=false, totalAmount: number = 0,option?:IArticleOption,deliveryCost?:number) => {
  // Determine the scale for price calculation
  const scale = totalAmount !== 0 ? totalAmount : amount;

  // Adjust the price based on the totalAmount or amount, for the scale
  const unitPrice = ChangePrice(scale, industrialPrice ? article.industry_price : article.customer_price);
  // Calculate totalPrice based on the original amount, but with the scaled unit price
  article.totalPrice = unitPrice * amount;
  if (option){
    let selectedAdditionalOptions = CalculateOptionPrice(option)
    article.totalPrice =  article.totalPrice + selectedAdditionalOptions
  }



  if (deliveryCost){
    article.totalPrice = article.totalPrice + deliveryCost
  }


  return article.totalPrice;
};

export const IsMinimumOrderQuantity = (article: IArticleOption|IArticle, amount: number, industrialPrice=false) => {
  const priceScale = industrialPrice ?  article.industry_price : article.customer_price;
  return (priceScale[0].scale >= amount);
}

export const ChangePrice = (scale: number, priceArray: { scale: number; price: number }[]): number => {
  let price = 0;
  priceArray.sort((a, b) => a.scale - b.scale);
  for (const priceEntry of priceArray) {
    if (scale >= priceEntry.scale) {
      price = priceEntry.price;
    } else {
      break; // Stop searching once we find the correct price for the scale
    }
  }
  return price;
};

// get if the Variants ar Multi or not
export const IsMultiVariant = (articleOptions: IOriginalArticleOptions) => {
  let isMultiVariant = false;
  Object.values(articleOptions).forEach((articleOptions: IArticleOption[]) => {
    if (articleOptions.length > 1) {
      isMultiVariant = true;
    }
  });
  return isMultiVariant;
}

export const GetAvailableVariantsByGroup = (articleOptions: IOriginalArticleOptions, group: string) => {
  const options: IArticleOption[] = [];
  Object.values(articleOptions).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      if (option.group === group) {
        options.findIndex(o => o.title === option.title) === -1 && options.push(option);
      }
    });
  });
  return options;
}

export const GetAvailableVariantsByMainGroupAndGroup = (articleOptions: IOriginalArticleOptions, mainGroup: string, mainTitle: string, group: string) => {
  let tempOptions: IArticleOption[] = [];
  let options: IArticleOption[] = [];
  // Get the main options for the main group and title
  Object.values(articleOptions).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      if (option.group === mainGroup && option.title === mainTitle) {
        tempOptions.push(option);
      }
    });
  });
  // Get the options for the group
  Object.values(articleOptions).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      if (option.group === group && tempOptions.findIndex(option$ => (option$.article_id === option.article_id)) !== -1) {
        options.push(option);
      }
    });
  });
  return options;
}


export const FindPath = (categories: ICategory[], categoryId: number): ICategory[] | null => {
  for (const category of categories) {
    const categoryId$ = category.id || category.main_id;
    if (categoryId$?.toString() === categoryId.toString()) {
      return [category];
    }
    if (category.subcategories && category.subcategories.length > 0) {
      const subPath = FindPath(category.subcategories, categoryId);
      if (subPath) {
        return [category, ...subPath];
      }
    }
  }

  return null;
}


export const SummarizeArticleOptions = (article: IArticle) => {
  const options = {};
  Object.values(article.options).forEach((articleOptions: IArticleOption[]) => {
    articleOptions.forEach(option => {
      const {
        group,
        title,
        stock,
        always_in_stock,
        industry_price,
        customer_price,
        images,
        main_image,
        article_id
      } = option;

      if (group === "Geschmackssorte") {
        options[title] = {
          group: "Geschmackssorte",
          title,
          always_in_stock: always_in_stock,
          sizes: [],
          main_image: main_image,
          stock,
          industry_price,
          customer_price,
          images,
          article_id
        };
      }

      if (group === "Farbe") {
        if (!options[title]) {
          options[title] = {
            group: "Farbe",
            title,
            always_in_stock: always_in_stock,
            sizes: [],
            main_image: main_image,
            stock,
            industry_price,
            customer_price,
            images,
            article_id
          };
        }
      } else if (group === "Größe") {
        const colorOption = articleOptions.find(o => o.group === "Farbe");
        if (colorOption && options[colorOption.title]) {
          options[colorOption.title].sizes.push({
            group: "Größe",
            totalPrice: 0,
            title,
            stock,
            always_in_stock,
            industry_price,
            customer_price,
            images,
            main_image,
            article_id
          });
        }
      }
    });
  });

  // Convert the map to an array for the final structure
  // Example of how to use summarizeOptions
  // Assuming you want to attach this back to the article or handle it differently
  return Object.values(options);

}

export const ValidateArticle = async (options: IArticleOption[]): Promise<boolean> => {
  for (const option of options) {
    // Validate required articles
    const requiredArticlesValid = option.requiredArticles
        ? Object.values(option.requiredArticles).every((nestedOptions) => {
          return (nestedOptions as IArticleOption[]).some((nestedOption) =>
              option.selectedRequiredArticles?.some(
                  (selected) => selected.connectId === nestedOption.connectId
              )
          );
        })
        : true;

    if (!requiredArticlesValid) {
      await ErrorToast(
          i18n("Please complete the required information to continue.")
              .t("de", "Bitte vervollständigen Sie die erforderlichen Angaben, um fortzufahren.")
              .get(),
          5000
      );
      return false;
    }

    // Validate characterCount for required articles
    if (option.selectedRequiredArticles) {
      for (const requiredArticle of option.selectedRequiredArticles) {
        if (requiredArticle.characterCount > 0 && !requiredArticle.printText) {
          await ErrorToast(
              i18n("Please enter a text in the required fields.")
                  .t("de", "Bitte geben Sie einen Text bei den erforderlichen Angaben ein.")
                  .get(),
              5000
          );
          return false;
        }
      }
    }

    // Validate characterCount for optional articles
    if (option.selectedOptionArticles) {
      for (const optionalArticle of option.selectedOptionArticles) {
        if (optionalArticle.characterCount > 0 && !optionalArticle.printText) {
          await ErrorToast(
              i18n('Please enter a text for the optional information or select “No specification”.')
                  .t("de", 'Bitte geben Sie einen Text bei den optionalen Angaben ein oder wählen Sie "Keine Angabe" aus.')
                  .get(),
              5000
          );
          return false;
        }
      }
    }

    // Recursively validate nested required articles
    const nestedRequiredValid = option.requiredArticles
        ? Object.values(option.requiredArticles).every((nestedOptions) =>
            ValidateArticle(nestedOptions as IArticleOption[])
        )
        : true;

    if (!nestedRequiredValid) {
      await ErrorToast(
          i18n("Please select all nested required options")
              .t("de", "Bitte wählen Sie alle verschachtelten erforderlichen Optionen aus")
              .get(),
          5000
      );
      return false;
    }
  }

  return true;
};



export const shrinkArticleParameters = (articles: IArticle[],file_server:string) => {

  const reduceArticleOptions = (options: IArticleOption[]): Partial<IArticleOption>[] => {
    return options.map((option:IArticleOption) => ({
      connectId: option.connectId?.toString(),
      amount: option.amount,
      printText: option.printText,
      price:option.price,
      name:option.articleText.de.name,
      title: option.title,
      requiredArticle:option.requiredArticle,
      selectedOptionArticles: option.selectedOptionArticles
          ? reduceArticleOptions(option.selectedOptionArticles)
          : undefined,
      selectedRequiredArticles: option.selectedRequiredArticles
          ? reduceArticleOptions(option.selectedRequiredArticles)
          : undefined,
    }));
  };

  const originalArray = articles.map((obj:IArticle) => {
    const filteredObj = {
      connectId: obj.connect_id,
      articleId: obj.id,
      preview_url: file_server + obj.main_image,
      vat: obj.vat,
      amount: obj.amount,
      price: obj.price,
      name: obj.name,
      selectedAdditionalOptions: obj.selectedAdditionalOptions
          ? reduceArticleOptions(obj.selectedAdditionalOptions)
          : undefined,
      selectedOption: obj.selectedOption
        ? {
          group: obj.selectedOption.group,
          title: obj.selectedOption.title,
        }
        : undefined,
      selectedOptions: obj.selectedOptions
        ? obj.selectedOptions.map((option: any) => ({
          group: option.group,
          title: option.title,
        }))
        : undefined,
    };

    return Object.fromEntries(
      Object.entries(filteredObj).filter(([_, value]) => value !== undefined)
    );
  });
  const removeEmptyProperties = (obj: any): any => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => {
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          // Recursively clean the object if it's a non-array object
          const cleanedObj = removeEmptyProperties(value);
          return Object.keys(cleanedObj).length > 0;
        }
        return value !== undefined;
      })
    );
  };
  return originalArray.map(item => removeEmptyProperties(item))
}
